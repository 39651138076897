import domMutation from './dom-mutation.service';
import $ from 'cash-dom';

class ViewportEventsService {
    public ticking = false;
    public tickCallbacks = {};
    public callbackIndexer = 0;
    public viewport = {
        width: this.getWindowWidth(),
        height: window.innerHeight,
        scrollX: this.getPageOffset('x'),
        scrollY: this.getPageOffset('y')
    };

    constructor() {
        $(window)
            .on('scroll', this.scrollHandler.bind(this))
            .on('resize', this.resizeHandler.bind(this))
            .on('orientationchange', this.resizeHandler.bind(this));

        domMutation.observe(document, this.requestTick.bind(this));
        this.requestTick();
    }

    setCallback(callback): number {
        this.callbackIndexer++;
        this.tickCallbacks[this.callbackIndexer] = callback;
        return this.callbackIndexer;
    }

    removeCallback(index: number) {
        delete this.tickCallbacks[index];
    }

    getPageOffset(axis) {
        return axis === 'y'
            ? window.pageYOffset || document.documentElement.scrollTop
            : window.pageXOffset || document.documentElement.scrollLeft;
    }

    tick() {
        this.ticking = false;
        Object
            .keys(this.tickCallbacks)
            .forEach(key => this.tickCallbacks[key] && this.tickCallbacks[key].call(this.tickCallbacks[key], this.viewport));
    }

    requestTick() {
        if (!this.ticking) {
            requestAnimationFrame(this.tick.bind(this));
        }
        this.ticking = true;
    }

    scrollHandler() {
        this.viewport.scrollX = this.getPageOffset('x');
        this.viewport.scrollY = this.getPageOffset('y');
        this.requestTick();
    }

    resizeHandler() {
        this.viewport.width = this.getWindowWidth();
        this.viewport.height = window.innerHeight;
        this.requestTick();
    }

    getWindowWidth() {
        return Math.max(window.innerWidth, window.document.documentElement.clientWidth);
    }
}

export default new ViewportEventsService();
