declare var window: IWindow;
class DictionaryService {
    constructor() {
        if (!window._app.dictionary) {
            throw new Error("### Dictionary not set ###");
        }
    }
    public get(key: string, args: string[] = []) {
        const translated = window._app.dictionary[key];
        if (translated === undefined || translated === null) {
            console.warn(`### Key '${key}' not found in dictionary ###`);
            return key;
        }
        return this.format(translated, args);
    }

    private format(translated, args: string[] = []): string {
        return args.reduce((result, arg, ix) => {
            return result.replace(`{${ix}}`, arg);
        }, translated);
    }
}

export default new DictionaryService();
