import Vue, { onBeforeUnmount, ref } from 'vue';
import viewportEvents from '../viewport/viewport-events.service';
import breakpointService from './breakpoint.service';
import { forEach, throttle } from 'lodash';
import { Mixin } from 'vue-mixin-decorator';

class Worker {
    private throttleTimer = 1000;
    private winWidth = 0;
    private checkActiveBreakpointThrottled: any;

    constructor(private callback: any) {
        this.checkActiveBreakpointThrottled = throttle(
            this.handleViewportEvents.bind(this),
            this.throttleTimer,
            { trailing: true }
        );

        viewportEvents.setCallback(this.checkActiveBreakpointThrottled);
    }

    public destroy() {
        viewportEvents.removeCallback(this.checkActiveBreakpointThrottled);
    }

    public handleViewportEvents(viewport: any) {
        const currentWinWidth = viewport.width;
        if (currentWinWidth === this.winWidth) {
            return;
        }
        this.winWidth = currentWinWidth;
        this.callback();
    }
}

@Mixin
export class BreakpointsMixin extends Vue {
    public breakpointIsActive : {
        large: boolean,
        medium: boolean,
        small: boolean,
    } = {
            large: false,
            medium: false,
            small: false
        };

    private $$breakpointWorker: Worker;
    public created() {
        this.$$breakpointWorker = new Worker(() => {
            forEach(breakpointService.breakpoints, (_, key) => {
                this.$set(
                    this.breakpointIsActive,
                    key,
                    breakpointService.isActiveBreakpoint(key)
                );
            });
        });
    }

    get isLargeBreakpoint() {
        return breakpointService.isActiveBreakpoint('large');
    }

    get isSmallBreakpoint() {
        return breakpointService.isActiveBreakpoint('small');
    }

    public beforeDestroy() {
        if (this.$$breakpointWorker) {
            this.$$breakpointWorker.destroy();
            this.$$breakpointWorker = null;
        }
    }
};

export default function useBreakpoints() {
    const breakpointIsActive = ref<{
        large: boolean,
        medium: boolean,
        small: boolean,
    }>({
        large: false,
        medium: false,
        small: false
    });

    let $$breakpointWorker = new Worker(() => {
        forEach(breakpointService.breakpoints, (_, key) => {
            breakpointIsActive.value[key] = breakpointService.isActiveBreakpoint(key);
        });
    });

    onBeforeUnmount(() => {
        if ($$breakpointWorker) {
            $$breakpointWorker.destroy();
            $$breakpointWorker = null;
        }
    });

    return breakpointIsActive;
}
