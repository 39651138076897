import { findKey, includes, isArray, isString, map, trim } from "lodash";

const breakpoints: any = {};

export function addBreakpoint(name: string, min: number, max: number) {
    breakpoints[name] = {
        min,
        max
    };
    return;
}

function normalizeBreakpoints(bps: string) {
    const normalizedBps = isString(bps)
        ? bps.split(",")
        : isArray(bps) ? bps : [];
    return map(normalizedBps, trim);
}

function isActiveBreakpoint(bps: string) {
    const arrayOfBreakpointNames = normalizeBreakpoints(bps);
    const activeBreakpoint = getActiveBreakpoint();
    return includes(arrayOfBreakpointNames, activeBreakpoint);
}

function getActiveBreakpoint(): string {
    const width = Math.max(
        window.innerWidth,
        window.document.documentElement.clientWidth
    );

    const activeBreakpoint = findKey(breakpoints, (bp) => {
        return bp.min <= width && bp.max >= width;
    });

    return activeBreakpoint;
}

export default {
    breakpoints,
    normalizeBreakpoints,
    isActiveBreakpoint,
    getActiveBreakpoint
};
