import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import DictionaryService from './dictionary.service';

@Mixin
export class TranslateMixin extends Vue {
    t(key: string, args: string[] = []) {
        return DictionaryService.get(key, args);
    }
}

export function useTranslations() {
    function t(key: string, args: string[] = []) {
        return DictionaryService.get(key, args);
    }

    return { t };
}
